;(function ($) {
	$.fn.countUp = function () {
		return $(this).each(function () {
			var $this = $(this),
				countTo = $this.data('countup');

			$({countNum: $this.text()}).animate({countNum: countTo}, {
				duration: 3500,
				easing: 'linear',
				step: function () {
					$this.text(Math.floor(this.countNum));
				},
				complete: function () {
					$this.text(this.countNum);
				}
			});
		});
	};
	$(function () {
		setTimeout(function () {
			$('[data-countup]').countUp();
		}, 500);
	});
})(jQuery);
