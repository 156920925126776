;(function ($) {
	$.fn.newsletterPopup = function () {
		return $(this).each(function () {
			var container = $(this);

			container.on('click', function(e) {
				e.preventDefault();
				window.open('/newsletter', '', 'width=600,height=600');
			});
		});
	};
	$(function () {
		$('[data-open-newsletter-popup]').newsletterPopup();
	});
})(jQuery);
