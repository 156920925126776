;(function ($) {

    function mergeObjects(obj1, obj2) {
        var merged = {};
        for (var attrname1 in obj1) {
            if (obj1.hasOwnProperty(attrname1)) {
                merged[attrname1] = obj1[attrname1];
            }
        }
        for (var attrname2 in obj2) {
            if (obj2.hasOwnProperty(attrname2)) {
                merged[attrname2] = obj2[attrname2];
            }
        }
        return merged;
    }

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    var calculateAvailablePoints = function (questions) {
        var availablePoints = 0;
        $(questions).each(function () {
            var question = this;
            var highestQuestionPoints = 0;
            // todo: support rangeSlider questions
            $(question.answers).each(function () {
                var answer = this;
                highestQuestionPoints = Math.max(highestQuestionPoints, answer.value);
            });
            availablePoints += highestQuestionPoints;
        });
        return availablePoints;
    };

    var _answersData;
    var getAnswersData = function () {
        if (typeof _answersData === "object") {
            return _answersData;
        }
        _answersData = JSON.parse(getCookie('answersData'));
        if (_answersData === null) {
            _answersData = {
                givenAnswers: [],
                currentPoints: 0
            };
        }
        return _answersData;
    };
    var addAnswer = function (questionId, answerData) {
        var answersData = getAnswersData();
        answersData.givenAnswers.push({
            questionId: questionId,
            answerData: answerData
        });
        answersData.currentPoints += answerData.resultingPoints;
        _answersData = answersData;
        setCookie('answersData', JSON.stringify(answersData), 7);
    };

    var getAnswerIdForRangeSliderValue = function (questionData, value) {
		return questionData.options[value].id;
    };

	var getPointsForRangeSliderValue = function (questionData, value) {
		return questionData.options[value].value;
	};

    var calculateScore = function (currentPoints, availablePoints) {
        return Math.round(currentPoints / availablePoints * 100);
    };

    $.fn.mappQuiz = function () {
        return $(this).each(function () {
            // init
            var container = $(this);
            var questions = container.data('quiz');
            var view = container.data('quizView');
            var quizBaseData = {
                questions: questions,
                availablePoints: calculateAvailablePoints(questions)
            };

            var getQuestionByIndex = function (index) {
                var questionRecord = quizBaseData.questions[index];
                if (!questionRecord) {
                    return null;
                }
                var questionType;
                if (questionRecord.type === 1) {
                    if (questionRecord.answers.length > 2) {
                        questionType = 'radio';
                    } else {
                        questionType = 'buttons';
                    }
                } else {
                    questionType = 'rangeSlider';
                }

                var questionNumber = index + 1;

                var questionData = {
                    id: questionRecord.id,
                    number: questionNumber,
                    type: questionType,
                    title: questionRecord.title,
                    tip: {
                        quote: questionRecord.tip,
                        name: questionRecord.tip_name,
                        position: questionRecord.tip_position,
                        company: questionRecord.tip_company
                    },
                    visualNumber: ('0' + questionNumber).slice(-2)
                };

				var options = [];
				$(questionRecord.answers).each(function () {
					var answer = this;
					options.push({
						id: answer.id,
						text: answer.title,
						value: answer.value
					});
				});
				questionData.options = options;

                return questionData;
            };

            var getActiveQuestion = function () {
                return getQuestionByIndex(getAnswersData().givenAnswers.length);
            };

            if (view === 'game') {
                var directives = {
                    common: {
                        '[data-number-of-questions]': 'numberOfQuestions',
                        '[data-number-of-current-question]': 'activeQuestion.number',
                        'h1': 'activeQuestion.title',
                        '[data-score-progress-bar-inner]@style': function () {
                            return 'width:' + calculateScore(this.points.current, this.points.available) + '%';
                        },
                        '[data-score-output]': function () {
                            return Math.round(calculateScore(this.points.current, this.points.available)) + '&nbsp;%';
                        },
                        '[data-tip-container] q': 'activeQuestion.tip.quote',
                        '[data-tip-container] span.name': 'activeQuestion.tip.name',
                        '[data-tip-container] span.position': 'activeQuestion.tip.position',
                        '[data-tip-container] span.company': 'activeQuestion.tip.company',
                        '[data-visual-mobile]@src': '/public/images/visuals/Visual_Q#{activeQuestion.visualNumber}_mobile@2x.png',
                        '[data-visual-desktop]@src': '/public/images/visuals/Visual_Q#{activeQuestion.visualNumber}_desktop@2x.png'
                    },
                    radio: {
                        '[data-answers-container] > label': {
                            'option<-activeQuestion.options': {
                                'p': 'option.text',
                                'input@value': 'option.id',
                                'input@data-resultingpoints': 'option.value'
                            }
                        }
                    },
                    rangeSlider: {
                    	'[data-answers-container] > span': {
							'option<-activeQuestion.options': {
								'.': 'option.text',
								'.@data-resultingpoints': 'option.value',
								'.@data-title': 'option.text'
							}
						}
					},
                    buttons: {
                        '[data-answers-container] > .button-container': {
                            'option<-activeQuestion.options': {
                                'button': 'option.text',
                                'button@value': 'option.id',
                                'button@data-resultingpoints': 'option.value'
                            }
                        }
                    }
                };
                var renderTemplates = {
                    radio: container.find('[data-template="radio"]').compile(mergeObjects(directives.common, directives.radio)),
                    rangeSlider: container.find('[data-template="rangeSlider"]').compile(mergeObjects(directives.common, directives.rangeSlider)),
                    buttons: container.find('[data-template="button"]').compile(mergeObjects(directives.common, directives.buttons))
                };
                // render quiz
                var renderQuiz = function () {
                    var activeQuestions = getActiveQuestion();
                    if (!activeQuestions) {
                        // no question available anymore => redirect to results page
                        window.location.href = "/result";
                        return;
                    }
                    var templatingData = {
                        numberOfQuestions: quizBaseData.questions.length,
                        activeQuestion: getActiveQuestion(),
                        points: {
                            current: getAnswersData().currentPoints,
                            available: quizBaseData.availablePoints
                        }
                    };
                    container.find('[data-quiz-canvas]').html(renderTemplates[templatingData.activeQuestion.type](templatingData));
					$('[data-range-slider]').rangeSlider();
                };
                var setGameEventHandlers = function () {
                    $(document).on('click', '[data-answer-submit]', function () {
                        var button = $(this);
                        var answerData = (function () {
                            var templateContainer = button.closest('[data-template]');
                            var type = templateContainer.data('template');
                            if (type === "rangeSlider") {
                                var selectedValue = templateContainer.find('input[name="answer"]').val();
                                return {
                                    answerId: getAnswerIdForRangeSliderValue(getActiveQuestion(), selectedValue),
                                    resultingPoints: getPointsForRangeSliderValue(getActiveQuestion(), selectedValue)
                                };
                            } else {
                                if (type === "button") {
                                    return {
                                        answerId: button.data('answer'),
                                        resultingPoints: button.data('resultingpoints')
                                    };
                                }
                                if (type === "radio") {
                                    var checkedRadio = templateContainer.find('input[name="answer"]:checked');
                                    if (checkedRadio.length > 0) {
                                        return {
                                            answerId: checkedRadio.val(),
                                            resultingPoints: checkedRadio.data('resultingpoints')
                                        };
                                    } else {
                                        return false;
                                    }
                                }
                            }
                        })();

                        if (answerData === false) {
                            return;
                        }

                        addAnswer(getActiveQuestion().id, answerData);
                        renderQuiz();
                    });
                };
                setGameEventHandlers();
                renderQuiz();
            }
            if (view === 'result') {
                var score = calculateScore(getAnswersData().currentPoints, quizBaseData.availablePoints);
                container.find('[data-score-output]').find('[data-countup]').attr('data-countup', score);

                container.find('[data-score-switch]').each(function () {
                    var scoreSwitch = $(this);
                    var resultContents = scoreSwitch.find('[data-score-threshold]').hide();
                    var selectedResultContent;
                    resultContents.each(function () {
                        var resultContent = $(this);
                        if (score >= resultContent.data('scoreThreshold')) {
                            selectedResultContent = resultContent;
                        }
                    });
                    selectedResultContent.show();
                });

				container.find('[data-score-progress-bar-inner]').css('width', score + '%');
            }
        });
    };
    $(function () {
        $('[data-quiz]').mappQuiz();
    });
})(jQuery);
