;(function ($) {
	$.fn.rangeSlider = function () {
		return $(this).each(function () {
			var container = $(this);
			var input = container.find('input');
			var indicators = container.find('span');

			indicators.off().on('click', function() {
				var index = indicators.index($(this));
				input.val(index);
			});
		});
	};
	$(function () {
		$('[data-range-slider]').rangeSlider();
	});
})(jQuery);
