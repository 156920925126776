;(function ($) {
	$.fn.styledInputs = function () {
		return $(this).each(function () {
			var container = $(this);
			var input = container.find('input, select');
			input.first().on('focus change', function() {
				container.addClass('input-active');
			});

			input.filter('input').first().on('focusout blur', function() {
				if(input.val() === '') {
					container.removeClass('input-active');
				}
			});
		});
	};
	$(function () {
		$('[data-input]').styledInputs();
	});
})(jQuery);
