;(function ($) {
	$.fn.animatedScrollto = function () {
		return $(this).each(function () {
			var container = $(this);

			container.on('click', function(e) {
				e.preventDefault();
				var scrolltoElement = $('#'+container.data('animatedscrollto'));
				$('html, body').animate({
					scrollTop: scrolltoElement.offset().top
				}, 1000);
			});
		});
	};
	$(function () {
		$('[data-animatedscrollto]').animatedScrollto();

		setTimeout(function () {
			var scrolltome = $('body').find('[data-scrolltome]');

			if (scrolltome.length > 0) {
				$('html, body').animate({
					scrollTop: scrolltome.offset().top
				}, 500);
			}
		}, 250);
	});
})(jQuery);
